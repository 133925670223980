<template>

    <div class="page-container">
        
        <Header ref="header" @sendCmd="sendCmd" :showPages="true" :showCmd="true"></Header>
        <Python ref="page" />
        
    </div>

</template>

<script>
import Header from '@/views/pages/ide/examGrade/Header';
import Python from '@/views/pages/ide/examGrade/python/Python';
import { getExamData } from "@/api/exam";
import { getGradeStudent } from '@/api/examEval'

export default {
    name: 'exam-python-page',
    data: function() {
        return {
        }
    },
    created() {
    },
    mounted() {
    },
    activated() {
        // 加载页面参数
        let examId = ''
        if (this.$route.query.examId) {
            examId = this.$route.query.examId
        }
        let qid = ''
        if (this.$route.query.qid) {
            qid = this.$route.query.qid
        }

        // 当前环境
        let curEnv = 'exam'

        this.loadGradeData(examId)
        // 加载考试信息
        this.loadExamInfo(examId)
        // 加载数据
        this.$refs.page.loadPage(examId, qid, curEnv)
    },
    beforeRouteUpdate(to, from, next) {
        // 解决路由重复问题，从to中读取参数，手动更新页面
        let examId = to.query.examId
        let qid = to.query.qid

        // 当前环境
        let curEnv = 'exam'

        this.$refs.page.loadPage(examId, qid, curEnv)

        next()
    },
    methods: {
        sendCmd(data) {
            this.$refs.page.handleCmd(data)
        },
        async loadExamInfo(examId) {
            let res = await getExamData(examId);
            if (res.data.status == 2) {
                this.$router.replace({ path: '/exam/end' });
            }

            this.$store.commit("exam/SetExamInfo", {
                examId: res.data.examId,
                examTitle: res.data.examTitle,
                examName: res.data.examName,
                startTime: res.data.startTime,
                endTime: res.data.endTime,
                count: res.data.count,
                questionIds: res.data.questionIds,
                questionTypes: res.data.questionTypes,
            });

            this.$refs.header.initClock(res.data.startTime, res.data.endTime);
        },
        async loadGradeData(examId) {
            let res = await getGradeStudent(examId);
            if (res.data && res.data.examType) {
                this.$store.commit('exam/SetGradeData', {
                    qid: res.data.qid,
                    examNumber: res.data.examNumber,
                    examType: res.data.examType,
                    leftCount: res.data.leftCount
                })
            }
        }
    },
    components: {
        Header,
        Python
    }
}
</script>

<style lang="scss" scoped>
.page-container {
    height: 100%;
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    background-color: #222;
}
</style>
